import React, {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import getVariant from "api/requests/getVariant";
import postReservation from "api/requests/postReservation";
import { retrieveCart } from "api/requests/retrieveCart";
import { Action, Category } from "constants/events";
import { AddToBagErrorMetrics } from "constants/metrics";
import { Cart } from "custom-types/Cart";
import { MenuItem, MenuItemVariant } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import {
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  AddToCartAction,
  AddToCartFailureAction,
  AddToCartSuccessAction,
  GET_CART_FAILURE,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GetCartAction,
  GetCartFailureAction,
  GetCartSuccessAction,
} from "redux/reducers/cart";
import { getCartSessionId } from "redux/selectors/cart";
import { getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import isMobileDevice from "utils/isMobileDevice";
import logError from "utils/logError";
import {
  MENU_TITLES,
  MenuType,
  testVariantsForMenuType,
} from "utils/menuTypeUtils";
import { getSplitKey } from "utils/split/getSplitKey";

import { Modal } from "components/botanic/Modal";

import { MenuItemUnavailableNotice } from "./internal/MenuItemUnavailableNotice";
import { NewBag } from "./internal/NewBag";
import { VariantSelection } from "./internal/VariantSelection";

enum ModalState {
  BagFailed,
  VariantSelection,
  ResetBag,
  ResetMenu,
  Inactive,
}

type AddToBagModalProps = ComponentPropsWithoutRef<typeof Modal> & {
  /**
   * Tells the component to just add the selected variant to bag even if
   * there are multiple variants
   */
  immediatelyAddToBag?: boolean;
  /**
   * Flag to represent whether associated dispensary is enabled for
   * in store carts
   */
  inStoreCart?: boolean;
  /**
   * A callback that fires when modal has transitioned completely
   * to 'inactive' state and when add to bag is complete
   */
  onDismiss?: () => void;
  /**
   * A callback that fires when modal has successful reservation
   */
  onReservation?: () => void;
  /**
   * A callback that fires when modal has reservation failure
   */
  onReservationFail?: (error: string) => void;
  /**
   * quantity passed to reserve button
   */
  quantity?: number;
  /**
   * The ID of the menu item variant that should be displayed in the modal.
   * The associated menu item and all variants will be fetched, with the
   * variant indicated by this ID being the first option selected.
   */
  variantId?: number;
  /**
   * Category for event tracking
   */
  eventCategory?: Category;
  /**
   * Determines whether we show continue shopping button
   */
  showContinueShopping?: boolean;
};

export const noop = (): void => {
  /* do nothing */
};

/**
 * AddToBagModal displays the complete workflow for adding a menu item to the
 * bag. Most of the components in this collection of shared components are
 * presentational only, and this component breaks this pattern.
 */
export const AddToBagModal: React.FC<AddToBagModalProps> = ({
  immediatelyAddToBag,
  inStoreCart,
  onDismiss = noop,
  onReservation = noop,
  onReservationFail = noop,
  quantity = 1,
  variantId,
  eventCategory = Category.bag,
  showContinueShopping,
  ...props
}) => {
  const domainCountryCode = useDomainCountryCode();
  const cartSessionId = useSelector(getCartSessionId);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const { publishEvent } = useEventTracker();
  const dispatch = useDispatch();

  const splitKey = getSplitKey();

  const [menuItem, setMenuItem] = useState<MenuItem>();
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [staleBag, setStaleBag] = useState<Cart>();
  const [requiresNewBag, setRequiresNewBag] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>(ModalState.Inactive);
  const { pathname } = useRouter();

  const fetchCart = async () => {
    if (cartSessionId) {
      dispatch<GetCartAction>({
        type: GET_CART_REQUEST,
      });

      const response = await retrieveCart({
        cartId: cartSessionId,
        domainCountryCode: domainCountryCode,
      });

      if (response.error) {
        dispatch<GetCartFailureAction>({
          error: response.error,
          type: GET_CART_FAILURE,
        });
      } else if (response.cart) {
        dispatch<GetCartSuccessAction>({
          cart: response.cart,
          changes: response.changes || [],
          continueShopping: showContinueShopping
            ? {
                brandIsPaid: menuItem?.brand?.isPaid,
                dispensaryPath: menuItem?.dispensary?.path,
              }
            : {},
          type: GET_CART_SUCCESS,
        });

        return response.cart;
      }
    }

    return;
  };

  const fetchStaleCart = async () => {
    const cart = await fetchCart();

    if (cart) {
      setStaleBag(cart);
    }
  };

  const onReset = useCallback(() => {
    onDismiss();
    setRequiresNewBag(false);
    setStaleBag(undefined);
  }, [onDismiss]);

  const addToBag = async (variant: MenuItemVariant) => {
    if (!variant || !cartSessionId || !menuItem) {
      return;
    }

    dispatch<AddToCartAction>({
      type: ADD_TO_CART_REQUEST,
    });

    await postReservation({
      brandVerified: menuItem.isBrandVerified,
      cartId: cartSessionId,
      domainCountryCode,
      forceUpdate: requiresNewBag,
      menuItemId: menuItem.menuItemId,
      quantity,
      variant,
      ...(inStoreCart && { inStoreCart: true }),
    })
      .then(async () => {
        const isMobile = isMobileDevice();

        sendClientSideSplitTrackEvent(
          {
            eventType: "guardrail_addToBag_click",
            properties: { isMobile, page: pathname },
          },
          splitKey,
        );

        onReservation();

        dispatch<AddToCartSuccessAction>({
          type: ADD_TO_CART_SUCCESS,
          variantId: variant.id,
        });

        await fetchCart();

        if (modalState === ModalState.Inactive) {
          onReset();
        } else {
          setModalState(ModalState.Inactive);
        }
      })
      .catch(async (error: Error) => {
        if (error.message.includes("cart_mismatch_error")) {
          await fetchStaleCart();

          setRequiresNewBag(true);
          setModalState(ModalState.ResetBag);
          onReservationFail(AddToBagErrorMetrics.CartMismatch);
        } else if (error.message.includes("multiple_menu_type_error")) {
          setRequiresNewBag(true);
          setModalState(ModalState.ResetMenu);

          onReservationFail(AddToBagErrorMetrics.MultipleMenuType);
        } else {
          await fetchStaleCart();

          setModalState(ModalState.BagFailed);

          publishEvent({
            action: Action.impression,
            category: eventCategory,
            dispensaryId: menuItem.dispensaryId,
            errorMessage: error.message,
            label: inStoreCart
              ? "add to bag error - shop every menu"
              : "add to bag error",
          });

          logError(`Error adding to bag: ${error.message}`);

          onReservationFail(AddToBagErrorMetrics.Unknown);
        }

        dispatch<AddToCartFailureAction>({
          error,
          type: ADD_TO_CART_FAILURE,
        });
      });
  };

  const onAddToBagClick = async () => {
    const variant = menuItem?.variants.find((v) => v.id === selectedVariantId);
    if (variant) {
      addToBag(variant);
    }
  };

  const title = () => {
    switch (modalState) {
      case ModalState.VariantSelection:
        return "Choose your amount";
      case ModalState.BagFailed:
        return "Uh oh!";
      case ModalState.ResetBag:
        return inStoreCart
          ? "Create a new in-store cart?"
          : "Create a new cart?";
      case ModalState.ResetMenu: {
        const pendingBagItemMenuTypeLabels = userPrefersMedical
          ? MENU_TITLES.med.shortLower
          : MENU_TITLES.rec.shortLower;
        return `Switch to a ${pendingBagItemMenuTypeLabels} cart?`;
      }
      default:
        return "Add to cart";
    }
  };

  /**
   * This useEffect will fetch the menu item and variants data based on the
   * variantId prop, and set an initial menu type for dual-licensed dispensaries.
   */
  useEffect(() => {
    if (variantId && domainCountryCode) {
      getVariant(variantId, domainCountryCode).then((response) => {
        if (response) {
          setSelectedVariantId(variantId);
          setMenuItem(response);
        } else {
          setModalState(ModalState.BagFailed);
          onReservationFail(AddToBagErrorMetrics.BagLoad);
        }
      });
    }
  }, [variantId, domainCountryCode]);

  // Once we get the initial menu items from the variantId prop, we'll avoid
  // showing variant selection if there is only one variant or immediatelyAddToBag prop is
  // set to true and go straight to add to bag. Otherwise, we'll show the variant
  // selection.
  useEffect(() => {
    if (menuItem) {
      if (menuItem.variants?.length === 1 || immediatelyAddToBag) {
        const variant = menuItem.variants.find((v) => v.id === variantId);
        if (variant) {
          addToBag(variant);
        }
      } else {
        setModalState(ModalState.VariantSelection);
      }
    }
  }, [JSON.stringify(menuItem)]);

  // Adding this ref ensures that we don't do anything in this useEffect
  // right off the bat, as useEffect runs once on render and also when
  // modalState is changed
  const prevModalStateRef = useRef<ModalState>(modalState);
  useEffect(() => {
    if (
      modalState !== prevModalStateRef.current &&
      modalState === ModalState.Inactive
    ) {
      // Probably over precautious, but lets make sure that the modal has been rendered
      // inactive by state change before setting state and app-side dismiss.
      onReset();
    }

    prevModalStateRef.current = modalState;
  }, [modalState, onReset]);

  // If the user changes their menu type preference in the modal, this ensures
  // that the correct variant is displayed
  const hasDualLicense =
    menuItem && isDispensaryDualLicense(menuItem.dispensary.tags);
  useEffect(() => {
    if (hasDualLicense) {
      const variant = menuItem?.variants.find(
        testVariantsForMenuType(
          userPrefersMedical ? MenuType.Med : MenuType.Rec,
        ),
      );

      setSelectedVariantId(variant?.id);
    }
  }, [userPrefersMedical, JSON.stringify(menuItem?.variants), hasDualLicense]);

  return modalState !== ModalState.Inactive ? (
    <Modal
      title={title()}
      onDismissed={() => setModalState(ModalState.Inactive)}
      width={500}
      {...props}
    >
      {({ dismiss }: { dismiss: () => void }) => (
        <>
          {modalState === ModalState.BagFailed && (
            <MenuItemUnavailableNotice
              menuItem={menuItem}
              onKeepShoppingClick={dismiss}
              staleBag={staleBag}
              eventCategory={eventCategory}
            />
          )}

          {modalState === ModalState.VariantSelection && (
            <VariantSelection
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- menuItem is guaranteed to exist for ModalState.VariantSelection
              menuItem={menuItem!}
              onAddToBagClick={onAddToBagClick}
              onVariantSelect={(id) => {
                setSelectedVariantId(id);
              }}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- selectedVariantId is guaranteed to exist for ModalState.VariantSelection
              selectedVariantId={selectedVariantId!}
            />
          )}

          {(modalState === ModalState.ResetBag ||
            modalState === ModalState.ResetMenu) && (
            <NewBag
              inStoreCart={
                menuItem?.dispensary.inStoreCartEnabled ||
                staleBag?.dispensaryData.inStoreCartEnabled
              }
              newDispensary={menuItem?.dispensary}
              staleDispensary={staleBag?.dispensaryData}
              messaging={
                modalState === ModalState.ResetBag ? "dispensary" : "menu"
              }
              onKeepBagClick={dismiss}
              onNewBagClick={onAddToBagClick}
            />
          )}
        </>
      )}
    </Modal>
  ) : null;
};
